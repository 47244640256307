<template>
    <div :id="id"
         v-bind="computedTrustboxData">
        <a href="https://www.trustpilot.com/review/scoutandnimble.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
</template>

<script>
import { mapKeys, kebabCase } from 'lodash'

export default {
    props: {
        id: {
            type: String,
            required: true
        },
        trustboxData: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        defaultTrustboxData () {
            return {
                locale: 'en-US',
                businessunitId: this.trustpilotBusinessUnitId,
                theme: 'light'
            }
        },
        computedTrustboxData () {
            let data = { ...this.defaultTrustboxData, ...this.trustboxData }
            data = mapKeys(data, (_, key) => `data-${kebabCase(key)}`)

            return data
        },
        trustpilotBusinessUnitId () {
            return this.$config.TRUSTPILOT_BUSINESS_UNIT_ID
        }
    },
    watch: {
        'trustboxData.sku' () {
            this.loadTrustPilot()
        }
    },
    mounted () {
        this.loadTrustPilot()
    },
    methods: {
        loadTrustPilot () {
            if (!process.client) { return }

            this.$nextTick(() => {
                const trustbox = document.getElementById(this.id)

                if (trustbox && window.Trustpilot) {
                    window.Trustpilot.loadFromElement(trustbox, true)
                }
            })
        }
    }
}
</script>