<template>
  <a :href="mediaLinkMapping[type]"
     target="_blank"
     rel="noopener"
     :class="['social-link', `social-link--${type}`]"
     :aria-label="`Check out our ${type} profile`">
    <span class="social-link__icon-wrapper"><i :class="mediaIconClassMapping[type]"/></span>
  </a>
</template>

<script>
  export default {
    name: 'SocialLink',
    props: {
      type: {
        type: String,
        required: true,
        validator: (type) => ['pinterest', 'facebook', 'instagram'].includes(type)
      }
    },
    data () {
      return {
        mediaIconClassMapping: {
          pinterest: 'fab fa-pinterest',
          facebook: 'fab fa-facebook-f',
          instagram: 'fab fa-instagram'
        },
        mediaLinkMapping: {
          pinterest: 'http://pinterest.com/scoutandnimble/',
          facebook: 'https://facebook.com/scoutandnimble',
          instagram: 'http://instagram.com/scoutandnimble'
        }
      }
    }

  }
</script>

<style lang="scss" scoped>
  @import '@/assets/stylesheets/variables.scss';

  .social-link {
    background: $color-white;
    font-size: 4rem;
    width: 68px;
    height: 68px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.149);
    color: $sn-search-color;

    &--facebook {
      .social-link__icon-wrapper {
        display: inline-flex;
        background: $sn-search-color;
        width: 40px;
        height: 40px;
        color: $color-white;
        justify-content: center;
        align-items: flex-end;
        border-radius: 50%;
        font-size: 3.2rem;
      }
    }
  }
</style>
