<template>
    <img
            :data-src="lazySrc"
            :style="style"
            :alt="alt"
            :src="fallbackSrc"
            v-fallback-image="fallbackImage"
            :class="[{ 'spinner': this.loading }, lazyClass]"
    >
</template>

<script>
    import lozad from 'lozad';

    export default {
        name: 'lazy-img',
        props: {
            lazyClass: {
                type: String,
                default: null
            },
            lazySrc: {
                type: String,
                default: null,
            },
            fallbackImage: {
                type: String,
                default: null
            },
            backgroundColor: {
                type: String,
                default: 'transparent',
            },
            height: {
                type: Number,
                default: null,
            },
            width: {
                type: Number,
                default: null,
            },
            alt: {
                type: String,
                default: 'image'
            },
            loader: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                observer: null,
                loading: true
            };
        },
        watch: {
            lazySrc (newValue) {
                this.$el.removeAttribute('data-loaded')
                this.observer.observe()
            }
        },
        computed: {
            fallbackSrc () {
                return this.fallbackImage || 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
            },
            aspectRatio() {
                if (!this.width || !this.height) return null;

                return (this.height / this.width) * 100;
            },
            style() {
                const style = { backgroundColor: this.backgroundColor };

                if (this.width) style.width = `${this.width}px`;

                const applyAspectRatio = this.loading && this.aspectRatio;
                if (applyAspectRatio) {
                    style.height = 0;
                    style.paddingTop = `${this.aspectRatio}%`;
                }

                return style;
            }
        },
        methods: {
            setLoader () {
                if (!this.lazySrc || !this.loader) { return }

                this.loading = !this.$el.complete
            }
        },
        mounted() {
            this.setLoader()

            const setLoadingState = () => {
                this.loading = false
            }
            this.$el.addEventListener('load', setLoadingState)

            this.$once('hook:destroyed', () => {
                this.$el.removeEventListener('load', setLoadingState)
            })

            this.observer = lozad(this.$el, {
                threshold: [0.1]
            })
            this.observer.observe()
        }
    };
</script>

<style lang="scss" scoped>
.spinner {
    background-image: url('../assets/images/spinner.gif');
    background-repeat: no-repeat;
    background-position: center;
}
</style>