<template>
    <div>
        <div class="instagram-feed">
            <div class="instagram-img" v-for="(image, index) in instagramImages" :key="index">
                <a :href="image.permalink" target="_blank">
                    <transition name="fade">
                        <lazy-img :lazy-src="image.url" class="responsive" />
                    </transition>
                </a>
            </div>
        </div>
        <button v-show="!$fetchState.pending && instagramImagesNextPage"
                class="btn medium section-button"
                role="button"
                @click="loadInstagramImages">
            LOAD MORE
        </button>
    </div>
</template>

<script>
import ApiInstagramMedium from '@/api/InstagramMedium'
import LazyImg from '@/../shared/components/LazyImg'

export default {
    components: {
        LazyImg
    },
    data () {
        return {
            instagramImages: [],
            instagramImagesNextPage: 1
        }
    },
    methods: {
        loadInstagramImages () {
            return ApiInstagramMedium.getInstagramMedia(this.$axios, { media_type: 'image', page: this.instagramImagesNextPage, per_page: 8 })
                .then(response => {
                    this.instagramImages = [...this.instagramImages, ...response.data.data]
                    this.instagramImagesNextPage = response.data.meta.pagination.next
                })
                .catch(error => {
                    console.log(error)
                })
            },
    },
    fetchOnServer: false,
    async fetch () {
        this.loadInstagramImages()
    }
}
</script>

<style lang="scss" scoped>
.instagram-feed {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    align-items: center;
    justify-items: center;

    img {
        max-height: 300px;
    }
}

.section-button {
    max-width: 370px;
    margin: 35px auto 0;
    display: block;
}

@media only screen and (max-width: 600px) {
    .instagram-feed {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>
