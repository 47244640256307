<template>
    <header>
        <div class="image">
            <img src="@/assets/images/designers/designers_1.jpg" alt="designer_photo" loading="lazy">
        </div>

        <div class="info">
            <div class="info_container">
                <h1 class="info_title">Are you a Designer?</h1>
                <h2 class="info_subtitle">Join our Trade Program</h2>
                <div class="info_list">
                    <ul>
                        <li>Receive promotions &amp; incentives for you &amp; your clients</li>
                        <li>Shop top designer brands all in one place</li>
                        <li>Take 15% off every order</li>
                    </ul>
                </div>

                <nuxt-link :to="{ name: 'designers-signup' }">
                    <div class="btn medium info_button">JOIN NOW</div>
                </nuxt-link>

            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: "designer-invation"
    }
</script>

<style scoped lang="scss">
    @import '@/assets/stylesheets/unstylished-headers.scss';

   header {
        margin-top: 29px;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 16px;

        .image {
            max-width: 489px;

            img {
                width: 100%;
            }
        }

        .info {
            background-color: #f7f4f2;
            text-align: center;
            color: #151515;
            margin: 0 auto;
            width: 100%;
            max-height: 540px;

            .info_container {
                margin: 96px auto 30px;
                max-width: 508px;


                .info_title {
                    margin-bottom: 21px;
                }

                .info_subtitle {
                    margin-bottom: 39px;
                    line-height: 26px;
                }

                .info_list {
                    letter-spacing: 0.8px;

                    li {
                        padding-top: 20px;
                        padding-left: 5px;
                        text-align: left !important;
                        font-size: 1.6rem;
                        line-height: 2.19;
                    }
                }

                .info_button {
                    margin: 34px auto 0;
                    line-height: 32px;
                }
            }
        }

        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            grid-column-gap: 0px;

            .image {
                margin: 0 auto;
            }

            .info {
                .info_container {
                    margin: 43px auto 37px;
                    max-width: 600px;
                    .info_title {
                        margin-bottom: 5px;
                    }

                    .info_subtitle {
                        font-size: 24px;
                        line-height: 29px;
                        letter-spacing: 1.2px;
                    }

                    .info_list {
                        ul {
                            padding-left: 25px;
                            li {
                                margin: 0 20px 20px 5px;
                                list-style-type: disc;
                                text-align: center;
                                line-height: 24px;
                                letter-spacing: 0.8px;
                            }
                        }
                    }

                    .info_button {
                        max-width: 309px;
                    }

                }
            }
        }
    }

</style>
