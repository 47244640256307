<template>
    <client-only>
        <transition name="fade">
            <div class="wrapper" v-if="loading">
                <div class="categories-view" v-if="category || home">
                    <div class="header" :class="{home: home}"></div>
                    <div class="subcategories">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div class="product-list-wrapper">
                    <div class="product-list">
                        <div class="product" v-for="n in amount">
                            <div class="product-preview">
                                <content-placeholders-img />
                                <content-placeholders-text :lines="3" />
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </transition>
    </client-only>
</template>

<script>
    export default {
        name: 'products-loader',
        props: {
            loading: Boolean,
            category: Boolean,
            home: Boolean,
            amount: Array,
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/stylesheets/animations/fade';

    .wrapper {
        background-color: rgba(255, 255, 255, 0.3);

        .product-list {
            display: grid;
            grid-gap: 20px;
            grid-auto-rows: min-content;

            grid-template-columns: repeat(4, 1fr);

            .product-preview {

                .vue-content-placeholders-img {
                    height: 300px;
                    margin-bottom: 15px;
                }

                @media only screen and (max-width: 600px) {
                    .vue-content-placeholders-img {
                        height: 150px;
                    }
                }

                @media only screen and (min-width: 600px) and (max-width: 768px) {
                    .vue-content-placeholders-img {
                        height: 200px;
                    }
                }

                @media only screen and (min-width: 768px) and (max-width: 992px) {
                    .vue-content-placeholders-img {
                        height: 250px;
                    }
                }
            }
            @media only screen and (max-width: 992px) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    .categories-view {
        margin-bottom: 100px;

        .header {
            background-color: #eee;
            max-width: 1500px;
            height: 267px;

            &.home {
                height: 500px;
            }
        }

        .subcategories {
            display: flex;
            margin-top: 20px;
            justify-content: center;

            div {
                margin-right: 10px;
                width: 100px;
                height: 100px;
                background-color: #eee;
            }
        }
    }
</style>
